import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "text-center",
  "data-test-id": "header-login"
}
const _hoisted_2 = { class: "mt-12 d-grid" }
const _hoisted_3 = {
  class: "btn btn-primary",
  "data-test-id": "btn-login"
}
const _hoisted_4 = {
  key: 1,
  class: "mt-4 small text-center text-black-50"
}
const _hoisted_5 = { href: "#" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_Error = _resolveComponent("Error")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
  }, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('auth.login.title')), 1),
    (_ctx.message)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 0,
          class: "mt-6 text-center",
          message: _ctx.$t(_ctx.message.toString()),
          variant: "warning"
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    _createVNode(_component_FormGroup, {
      modelValue: _ctx.email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      autofocus: "",
      class: "mt-12",
      "data-test-id": "input-email",
      "error-key": "email",
      errors: _ctx.errors,
      label: _ctx.$t('auth.email'),
      name: "email",
      type: "email"
    }, null, 8, ["modelValue", "errors", "label"]),
    _createVNode(_component_PasswordField, {
      modelValue: _ctx.password,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
      class: "mt-12",
      "data-test-id": "input-password",
      errors: _ctx.errors,
      label: _ctx.$t('auth.password')
    }, null, 8, ["modelValue", "errors", "label"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.$t('auth.actions.login')), 1)
    ]),
    _createVNode(_component_Error, {
      class: "text-center mt-4 d-block",
      "data-test-id": "error-default",
      "error-key": "login",
      errors: _ctx.errors
    }, null, 8, ["errors"]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.login.noAccount')) + " ", 1),
          _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('auth.actions.signup')), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_RouterLink, {
      class: "mt-4 text-center small d-block",
      to: {name: 'auth.forgotPassword', query: _ctx.email ? {email: _ctx.email} : {}}
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('auth.login.forgotPassword')), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ], 32))
}