
import {defineComponent} from 'vue';

/**
 * Shows an alert banner with the message by default.
 * The contents of the Alert can be changed by overriding the default slot.
 */
export default defineComponent({
    props: {
        message: {
            type: String,
            default: '',
        },
        variant: {
            type: String,
            default: 'info',
        },
    },
});
