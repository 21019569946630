
import {defineComponent, ref} from 'vue';
import Alert from '@/components/common/Alert.vue';
import Error from '@/components/common/Error.vue';
import Errors from '@/library/model-collection/Errors';
import FormGroup from '@/components/common/form/FormGroup.vue';
import PasswordField from '@/components/common/form/PasswordField.vue';
import auth from '@/store/auth';
import config from '@/library/data/config';
import {useRoute} from 'vue-router';

export default defineComponent({
    components: {
        Error,
        Alert,
        PasswordField,
        FormGroup,
    },
    setup() {
        const route = useRoute();

        return {
            email: ref<string>(''),
            password: ref<string>(''),
            errors: ref(new Errors()),
            message: ref(route.query.message),
        };
    },
    methods: {
        async login() {
            this.errors = new Errors();

            try {
                await auth.login(this.email, this.password);
            } catch (e: any) {
                this.password = '';

                if (!e.response) throw e;

                // Add 429 "Too many attempts." error to errors.
                if (e.response.status === 429) {
                    this.errors.setErrors({
                        login: [e.response.data.message],
                    });

                    return;
                }

                if (!e.response.data.errors) {
                    throw e;
                }

                this.errors.setErrors(e.response.data.errors);

                return;
            }

            let to = {path: '/'};

            const storedTo = sessionStorage.getItem(config.REDIRECT_AFTER_AUTH_KEY);

            if (storedTo) {
                const parsed = JSON.parse(storedTo);

                if (parsed) {
                    to = parsed;
                }

                sessionStorage.removeItem(config.REDIRECT_AFTER_AUTH_KEY);
            }

            await this.$router.push(to);
        },
    },
});
