import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert", _ctx.variant ? `alert-${_ctx.variant}` : '']),
    role: "alert"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.message), 1)
    ])
  ], 2))
}